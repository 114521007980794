.switch-tooltip+.customTextField {
    overflow: hidden;
}

.switch-tooltip+.customTextField input.inputField {
    padding-right: 26px;
}

.customTextField .inputLabel {
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.required {
        &:after {
            content: '*';
            margin-left: 4px;
        }
    }
}

.customTextField .inputLabel[data-shrink=true] {
    max-width: 510px;
    width: 100%;
    overflow: visible;
    text-overflow: unset;

}

.customTextField label {
    line-height: 1.5;
}

.customTextField label[data-shrink=false] {
    color: #FFFFFF7F;
}

.customTextField label:not(.custom-label)[data-shrink=true] {
    color: #000 !important;
}

.customTextField input.inputField {
    font-size: 14px !important;
    line-height: 18px;
    padding: 6px 0 7px;
    height: 1.1876em;
}

.customTextField .inputRoot.Mui-disabled input,
.customTextField .inputRoot.Mui-disabled input::placeholder {
    color: #000;
    -webkit-text-fill-color: #000;
}
.settings-user-details {
    .customTextField .inputRoot.Mui-disabled input,
    .customTextField .inputRoot.Mui-disabled input::placeholder {
        color: #fff;
        -webkit-text-fill-color: #fff;
    }
}

.customTextField input:-webkit-autofill,
.customTextField input:-webkit-autofill:hover,
.customTextField input:-webkit-autofill:focus,
.customTextField input:-webkit-autofill:active {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.customTextField .inputRoot.MuiInput-disabled-18:before,
.customTextField .inputRoot.MuiInput-disabled-18:after {
    border-bottom: none !important;
}

.customTextField .inputRoot:before {
    border-bottom: 1px solid #FFFFFF7F !important;
}

.feedIn-table,
.edit-tariff-form {
    .customTextField .inputRoot:before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
}

.customTextField .inputRoot:after {
    border-bottom: 1px solid var(--primary-color) !important;
}

@-webkit-keyframes autofill {
    to {
        color: #fff;
        background: transparent;
    }
}

.inputCustomWarning {
    color: #f4516c;
    font-weight: 400;
    padding-left: 1.6rem;
    margin: 8px 0 0;
}

.search-ip-btn {
    line-height: 0.3 !important;
}
