input.datepicker-input {
  font-size: 18px !important;
  line-height: 21px;
  padding: 8.5px 18.5px;
  border: none !important;
  color: #000 !important;
  
  &:hover,
  &:focus {
    border: none !important;
  }
}

.datepicker-input-field {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: #40424d;
    }

    &:hover,
    &:focus {
      .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-notchedOutline {
        border-color: #3f99cc;
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #3f99cc;
    }

    &:hover,
    &:focus {
      .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-notchedOutline {
        border-color: #3f99cc;
      }
    }
  }

  .inputRoot::after {
    border: none;
  }
}

.custom-date-input-warning {
  white-space: nowrap;
}
