.notification-switch-container .set-prof-head {
    text-transform: uppercase;
    color: #fff;
}

.notification-frequency-container .checkmark,
.notification-type-error-container .checkmark {
    border: 1px solid var(--primary-color) !important;
}

.notification-frequency-container form .checkmark::after,
.notification-type-error-container form .checkmark::after {
    width: 12px;
    height: 12px;
    top: 3px;
    left: 3px;
}

.notification-frequency-container span,
.notification-type-error-container span {
    color: #FFFFFFCC;
    font-weight: 500;
}

.notification-frequency-container label,
.notification-type-error-container label {
    margin: 12px 0 0 0;
}

.notification-switch-field {
    display: flex;
    flex-direction: row;
}

.notification-switch-field p {
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0 20px;
}

.notification-switch-field .switcher {
    margin-top: -3px;
}

.freq-text {
    color: #161B2B;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 0;
}

.m-checkbox.every-new-error-label {
    color: rgba(255, 255, 255, .5) !important;
    font-weight: 500;
    margin-left: 3px;
}

.m-checkbox.every-new-error-label>span {
    border: 1px solid var(--primary-color) !important;
    margin-left: -2px;
}

.notification-frequency-container,
.notification-type-error-container {
  .freq-text {
    color: #fff;
  }

  .white.dataSMID {
    color: rgba(255, 255, 255, .5) !important;
  }
}

.modal-body {
  .freq-text {
    color: #161B2B;
  }
}

.notification-type-error-container.role-type.black {
  .freq-text {
    color: #161B2B;
  }
}
.notification-type-error-container.white-text {
  span.dataSMID {
    color: #FFFFFFCC !important;
  }
}
