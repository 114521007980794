$deviceActivityWarningColor: #ff9e22;

.m-datatable.m-datatable--default {
  &.m-datatable--loaded>.m-datatable__table>.m-datatable__head .m-datatable__row>.m-datatable__cell {
    background-color: #fff;
  }

  >.m-datatable__table {
    background-color: #fff;
    min-height: auto !important;

    .m-datatable__body .m-datatable__row>.m-datatable__cell>span,
    .m-datatable__foot .m-datatable__row>.m-datatable__cell>span,
    .m-datatable__head .m-datatable__row>.m-datatable__cell>span {
      overflow: visible;
    }

    .m-datatable__body {
      .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid>input:checked~span {
        background-color: var(--primary-color);
      }

      .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid>span {
        background-color: #65add6;
      }

      .m-datatable__cell {

        &:nth-child(2) span,
        &:nth-child(3) span,
        &:nth-child(6) span,
        &:nth-child(7) span,
        .error_status_yes,
        span.error_status_no,
        &:first-child span,
        &:first-child span.m-card-user__name {
          color: #ffffff !important;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.48px;
        }

        &:nth-child(2) span {
          color: #68b0ee !important;
          font-weight: 300 !important;
        }

        &:first-child span,
        &:first-child span.m-card-user__name {
          font-weight: 500 !important;
        }

        &:first-child span.m-card-user__name {
          margin-left: 10px;
        }

        &.error_status_yes {
          color: #f4516c !important;

          .warning {
            color: $deviceActivityWarningColor !important;
          }
        }

        span.table-row-details-head {
          color: rgba(255, 255, 255, 0.5) !important;
          font-size: 14px;
          font-weight: 500;
        }

        span.table-row-details-td {
          color: #000 !important;
          font-size: 14px;
          font-weight: 400;
        }

        &:nth-child(2) span.sm_id {
          font-weight: 300;
          letter-spacing: 0.48px;
          color: #fff !important;
          font-size: 16px;
        }
      }
    }

    >.m-datatable__head .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid {
      background-color: #65add6;
    }

    >.m-datatable__head .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid>input:checked~span {
      background-color: var(--primary-color);
    }
  }

  >.m-datatable__pager {
    margin-bottom: 25px;

    >.m-datatable__pager-nav {
      margin-right: 20px;

      >li {
        >.m-datatable__pager-link {
          &:hover {
            text-decoration: none;
          }

          &:active,
          &:hover {
            border-color: var(--primary-color) !important;
            background-color: var(--primary-color) !important;
          }

          &.m-datatable__pager-link--first,
          &.m-datatable__pager-link--last,
          &.m-datatable__pager-link--next,
          &.m-datatable__pager-link--prev {
            color: var(--primary-color);
            background-color: rgba(255, 255, 255, 0.2) !important;
          }

          &.m-datatable__pager-link--first:not(.m-datatable__pager-link--disabled):hover,
          &.m-datatable__pager-link--prev:not(.m-datatable__pager-link--disabled):hover,
          &.m-datatable__pager-link--last:not(.m-datatable__pager-link--disabled):hover,
          &.m-datatable__pager-link--next:not(.m-datatable__pager-link--disabled):hover {
            color: black;
          }

          &.m-datatable__pager-link--disabled {
            cursor: initial;
            background-color: #fff !important;

            i {
              color: var(--primary-color);
            }
          }

          &.m-datatable__pager-link--active {
            background: #fff !important;
            color: #000;

            &:hover {
              background-color: var(--primary-color) !important;
              color: #fff;
            }
          }
        }
      }
    }

    >.m-datatable__pager-info {
      display: inline-block !important;

      .MuiInputBase-root {
        width: 67px;
      }

      .bootstrap-select {
        width: 70px !important;
      }

      .m-datatable__pager-size {
        padding: 0 !important;
        height: 2.5rem !important;
        margin-left: 0;
        border-radius: 20px !important;
        background-color: #65add6;
        color: #ffffff;
        border: none;

        &:hover {
          cursor: pointer;
          background-color: var(--primary-color);
          color: #282a3c;

          .MuiNativeSelect-icon {
            fill: #40424d;
          }

          option {
            &:hover {
              background-color: grey;
            }
          }
        }

        &:before,
        &:after {
          border: none !important;
        }

        &.show {
          >.btn.dropdown-toggle {
            background: var(--primary-color);
          }
        }

        .btn.dropdown-toggle {
          background-color: #65add6;
          color: #ffffff;

          &:hover,
          &:focus {
            background: var(--primary-color);
          }
        }

        option {
          background-color: #fff;
        }

        .nativeSelect {
          padding-left: 10px;
          padding-top: 0;
          padding-bottom: 0;
          font-weight: 400;
          font-size: 15px;
          height: 35px;
          line-height: 35px;
        }

        .nativeSelectMenuIcon {
          fill: var(--primary-color);
          top: calc(50% - 10px);
          right: 5px;
        }

        .nativeSelectRoot.nativeSelect.nativeSelectMenu {
          padding-right: 0;
        }

        .nativeSelectRoot.nativeSelect.nativeSelectMenu {
          padding-right: 0;
        }
      }

      .m-datatable__pager-detail {
        margin-left: 9px;
        color: var(--primary-color);
        font-size: 14px;
        font-weight: 300;

        &.records {
          width: 203px;
          height: 15px;
          font-size: 14px;
          font-weight: 300;
          line-height: 23px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.m-datatable__table {
  tr {
    border-bottom: 1px solid #fff;
  }

  .m-datatable__head {
    .m-datatable__row span {
      font-size: 16px;
      color: #8f939b !important;
      font-weight: 500;
      letter-spacing: 0.48px;
    }
  }
}

.m-datatable__row-detail {
  tr {
    border-bottom: none;
  }

  &.active-datatable-row {
    width: 100%;
  }

  .m-datatable__detail .m-datatable__row>.m-datatable__cell:last-child {
    padding: 5px 10px;
  }
}

tr.m-datatable__row.active-datatable-row {
  border-bottom: none;
}

.m-datatable__row.employees-table:hover {
  background-color: #e8e8e8;
}

.switcher-logging {
  span {
    display: flex;
    align-items: center;
  }
}

.chart-switcher {
  margin-left: 56px;

  span {
    font-size: 16px;
    color: var(--dark);
  }
}

.search-ip-btn {
  position: absolute;
  top: 20px;
  right: 0;
  height: 24px;
  min-width: 96px;
  color: var(--primary-color) !important;

  &:hover {
    color: #fff !important;
  }
}

.work_status {
  border: 1px solid #9699a2;
  border-radius: 10rem;
  padding: 2px 10px;
}

.priority_status_low {
  color: #cccd5d;
  background-color: #fefcea;
  padding: 2px 10px;
  border-radius: 10rem;
}

.priority_status_medium {
  color: #ff8700;
  background-color: #fef3e5;
  padding: 2px 10px;
  border-radius: 10rem;
}

.priority_status_high {
  color: #ff2c2c;
  background-color: #f9e3e3;
  padding: 2px 10px;
  border-radius: 10rem;
}

.send-email-btn {
  &:hover {
    #send-email-icon {
      color: #fff !important;
    }
  }

  #send-email-icon {
    color: #161B2B80 !important;
  }
}

.paddingLeft {
  padding-left: 36px;
}

.yellow-warning-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  background-color: var(--primary-color);
  border-radius: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: #40424d;
}

.MuiNativeSelect-select:focus {
  background: transparent !important;
}

.reboot-dropdown-wrapper {
  .reboot-dropdown-btn {
    padding: 0.65rem 1rem;
    border: 1px solid var(--primary-color);
    color: #000;
    line-height: 12px;
    font-size: 0.77em;
    text-transform: capitalize;
    border-radius: 19px;
    font-family: Poppins, sans-serif;
    font-weight: 400;

    i {
      padding-left: 35px;
      font-size: 12px;
      color: #000;
    }

    &:hover {
      background-color: var(--primary-color);
      color: #fff;

      i {
        color: #fff;
      }
    }

    &[aria-controls="menu-list-grow"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      i {
        transform: rotate(180deg);
        padding-left: 0;
        padding-right: 35px;
      }
    }
  }

  div[role="tooltip"] {
    z-index: 5;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      background-color: transparent;
      box-shadow: none;
    }
  }

  ul.MuiList-root.MuiList-padding {
    margin-top: -1px;
    padding-top: 4px;
    padding-bottom: 12px;
    background-color: #fff !important;
    min-width: 125px;
    border: 1px solid var(--primary-color);
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;

    li {
      font-size: 12px;
      color: #000;
      font-family: Poppins, sans-serif;

      &:first-child {
        border-bottom: 1px solid var(--primary-color);
      }

      &:hover {
        color: #fff;
        background-color: var(--primary-color);
      }
    }
  }
}

.priority-dropdown {
  .priority-dropdown-btn {
    padding: 0.65rem 1rem;
    line-height: 12px;
    font-size: 0.77em;
    border-radius: 19px;
    font-family: Poppins, sans-serif;
    text-transform: capitalize;
    width: 100px;

    i {
      margin-left: auto;
      font-size: 12px;
      color: #161b2b;
      opacity: 0.5;
    }

    &[aria-controls="menu-list-grow"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      i {
        transform: rotate(180deg);
      }
    }
  }

  .priority-dropdown-btn.priority-dropdown-low {
    color: #cccd5d;
    background-color: rgba(255, 255, 45, 0.1);
  }

  .priority-dropdown-btn.priority-dropdown-medium {
    color: #ff8700;
    background-color: rgba(255, 145, 0, 0.1);
  }

  .priority-dropdown-btn.priority-dropdown-high {
    color: #ff2c2c;
    background-color: rgba(255, 44, 44, 0.1);
  }

  div[role="tooltip"] {
    z-index: 5;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      background-color: transparent;
      box-shadow: none;
    }
  }

  ul.MuiList-root.MuiList-padding {
    margin-top: -1px;
    padding: 0;
    background-color: #fff !important;
    width: 100px;
    border: 1px solid #ccc;

    li {
      font-size: 12px;
      font-family: Poppins, sans-serif;

      &:hover {
        background-color: #33363e;
      }
    }

    li.priority_status_low {
      color: #cccd5d;

      &:hover {
        background-color: rgba(255, 255, 45, 0.05);
      }
    }

    li.priority_status_medium {
      color: #ff8700;

      &:hover {
        background-color: rgba(255, 145, 0, 0.05);
      }
    }

    li.priority_status_high {
      color: #ff2c2c;

      &:hover {
        background-color: rgba(255, 44, 44, 0.05);
      }
    }
  }
}

.workStatus-dropdown {
  .workStatus-dropdown-btn {
    padding: 0.65rem 1rem;
    border: 1px solid #9598a1;
    background-color: #fff;
    color: #161b2b;
    line-height: 12px;
    font-size: 0.77em;
    text-transform: capitalize;
    border-radius: 15px;
    font-family: Poppins, sans-serif;
    width: 175px;

    i {
      margin-left: auto;
      font-size: 12px;
      color: #161b2b;
      opacity: 0.5;
    }

    &:hover {
      background-color: #fff;
    }

    &[aria-controls="menu-list-grow"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      i {
        transform: rotate(180deg);
      }
    }
  }

  div[role="tooltip"] {
    z-index: 5;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      background-color: transparent;
      box-shadow: none;
    }
  }

  ul.MuiList-root.MuiList-padding {
    margin-top: -1px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff !important;
    color: #161b2b;
    width: 175px;
    border: 1px solid #9598a1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 92px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      background-color: #2b2d34;
      border: 6px solid rgba(0, 0, 0, 0);
      width: 16px;
      background-clip: padding-box;
      border-radius: 9999px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8f939b;
      border: 6px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      width: 3px;
    }

    li {
      font-size: 12px;
      color: #161b2b;
      font-family: Poppins, sans-serif;
      white-space: pre-wrap;

      &:hover {
        color: #999999;
      }
    }
  }
}

.access-label {
  border-radius: 15px;
  border: 2px solid #000;
  font-weight: 500;
  color: #000;
  font-size: 14px;
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .chart-switcher {
    margin-left: 16px;
  }
}

.render-paid-license {
  margin: -2px 2px 2px 0;
}