.creatingNewUserNote {
  .modal-dialog {
    max-width: 780px;
    width: 100%;
    height: 100%;

    .modal-title {
      margin-left: 0px !important;
      padding-left: 40px !important;

      svg {
        margin-right: 20px;
      }
    }

    .modal-body {
      margin-bottom: 20px !important;
    }

    .modal-footer {
      border-top: 1px solid #40424d !important;

      .btn.m-btn--pill.m-btn--air.btn-secondary.btn-settings.btn-popup-canc {
        background-color: #fff !important;
        color: #999999 !important;
        border: 1px solid #999999 !important;
        box-shadow: none !important;

        &:hover {
          background-color: #fff !important;
          color: #2d3037 !important;
          border: 1px solid #2d3037 !important;
        }
      }
    }
  }
}