@import "../../styles/source";

.overview-pie-charts-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  padding: 10px;
  @include set-responsive-property(10px, 10px, 20px 45px 30px);

  min-height: max(35vh, 400px);

  .title {
    font-weight: 300;
    color: #161B2B7F;
    text-align: center;
    @include adaptive-value(20, 27);
    @include set-responsive-property(300, 300, 400, font-weight);
  }

  .status-pie-chart,
  .monitoring-pie-chart {
    position: relative;
    height: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-width: 200px;
    flex: 1 1 50%;
    @include set-responsive-property(5px, 5px, 10px, gap);
  }

  .additional-info {
    display: grid;
    width: 90%;
    grid-template-columns: repeat(1, 1fr);

    @include set-responsive-property(0, 0px, 10px, gap);

    @media (min-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .empty {
      height: 20px;
    }

    .info-item {
      display: flex;
      align-items: space-between;

      .circle-marker {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        align-self: center;
        margin: 0 7px;
      }

      .value {
        margin-right: 7px;
        color: #161B2B;;
        font-weight: 500;
        line-height: 18px;
      }

      .name {
        color: rgba($color: #161B2B, $alpha: 0.5);
        font-weight: 400;
        white-space: nowrap;
        line-height: 18px;
      }

      .installation-ongoing {
        background-color: $status-installation-ongoing-color;
      }

      .connected {
        background-color: $status-connected-color;
      }

      .ok {
        background-color: $status-ok-color;
      }

      .warning {
        background-color: $status-warning-color;
      }

      .error {
        background-color: $status-error-color;
      }

      .not-connected {
        background-color: $status-not-connected-color;
      }
    }
  }
}