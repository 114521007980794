.m-login__container {
  .PhoneInputCountryIcon,
  .PhoneInputCountrySelectArrow {
    color: var(--primary-color);
  }

  .country-btn > span span:last-child {
    color: #000;
  }
  .inputRoot.Mui-disabled input,
  .inputRoot.Mui-disabled input::placeholder {
    color: #000 !important;
    -webkit-text-fill-color: #000;
  }
}
.form-group.m-form__group.input-field.sign-up-form2 {
  .flag-select__btn {
    color: #000 !important;
  }
  .customTextField .inputRoot.Mui-disabled input,
  .customTextField .inputRoot.Mui-disabled input::placeholder {
    color: #000 !important;
    -webkit-text-fill-color: #000 !important;
  }
}

.terms-condition-block {
  margin-bottom: -20px !important;
  height: 30px !important;
  color: red;
}
