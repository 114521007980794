.documentation-wrapper {
  .loader-wrapper {
    width: 100px;
    display: flex;
    justify-content: center;
  }

  .modal-body .message {
    color: #161B2B;
  }
}
