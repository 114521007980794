.error-filter__container {
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: url('../../../assets/images/svg/arrow-down-for-native-select.svg');

    position: absolute;
    top: 32px;
    right: 5px;
  }

  select {
    appearance: none;
    padding: 0 5px;
  }

  span {
    color: #ffffff7f;
    font-size: 14px;
  }

  .additional-filters__select {
    cursor: pointer;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.098);
    background-color: transparent;
    width: 250px;
    height: 38px;

    option {
      color: #9699a2;
    }
  }
}

.reboot-dropdown-wrapper {
  margin-right: 15px;

  .drop-down-list.drop-down-list-reboot {
    button.MuiButton-text.MuiButton-textPrimary {
      color: #fff !important;

      i.fa.fa-caret-down {
        color: #fff !important;
      }
    }
  }

  .drop-down-list-reboot .drop-down-list-reboot-btn {
    max-height: 38px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
  }
}

.install-firmware-btn .is-large-button {
  max-height: 38px;
}