.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sub-user-photo {
  max-width: 300px;
}

:-webkit-autofill {
  transition-delay: 9999s
}

.field-color > div > div > input {
  -webkit-text-fill-color: #fff !important;
}
