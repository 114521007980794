.employee-add-prealoder-container {
    height: 168px;
    display: flex;
    align-items: center;
}

.employee-delete-prealoder-container {
    height: 158px;
    display: flex;
    align-items: center;
}

.user-add-prealoder-container {
    height: 245px;
    display: flex;
    align-items: center;
}

.users-filter {
    padding: 12px 0;

    .input-field {
        height: auto !important;
        margin: 0 !important;
    }
}

.modal-add-user-to-viewer {
    max-height: 50vh;
}

.full-list {
    overflow-y: auto;
}

.modal-buttons {
    display: flex;
    margin-top: 4px;
}

#addEndUsersToViewerForm,
#addPVInstallersToSupportForm,
#addPVInstallersToViewerForm,
#addOEMToViewerForm {
    .m-checkbox.m-checkbox--state-warning>span:after {
        border-color: #fff !important;
    }
}

.checkbox-label-color {
    color: var(--dark) !important;
    width: max-content;
}