.drop-down-list {
  div[role='tooltip'] {
    z-index: 5;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      background-color: transparent;
      box-shadow: none;
    }
  }

  button.MuiButton-text.MuiButton-textPrimary {
    color: #fff;

    i.fa.fa-caret-down {
      color: #fff;
    }
  }

  .Mui-disabled {
    border-color: #ffffff7f !important;
    color: #ffffff7f !important;

    i.fa.fa-caret-down {
      color: #ffffff7f !important;
    }
  }
}

.react-table-v2,
.software-container {
  .drop-down-list {
    button.MuiButton-text.MuiButton-textPrimary {
      color: #000;

      i.fa.fa-caret-down {
        color: #000;
      }
    }
  }
}

.drop-down-list-priority {
  &-btn {
    padding: 0.65rem 1rem !important;
    line-height: 12px !important;
    font-size: 0.77em !important;
    border-radius: 19px !important;
    text-transform: capitalize !important;
    width: 100px !important;

    i {
      margin-left: auto;
      font-size: 12px;
      color: #161b2b !important;
      opacity: 0.5;
    }

    &-grow {
      text-wrap: wrap;

      &__low {
        color: #ffff2d;
        background-color: #414234;
        padding: 2px 10px;
        border-radius: 10rem;
      }

      i {
        transform: rotate(180deg);
      }
    }

    &__low {
      color: #ffcf2d !important;
      background-color: rgba(255, 255, 45, 0.1) !important;
    }

    &__medium {
      color: #ff8700 !important;
      background-color: rgba(255, 145, 0, 0.1) !important;
    }

    &__high {
      color: #ff2c2c !important;
      background-color: rgba(255, 44, 44, 0.1) !important;
    }
  }

  &-grow {
    &__low {
      color: #ffcf2d;
      background-color: #414234;
      padding: 2px 10px;
      border-radius: 10rem;
    }

    &__medium {
      color: #ff8700;
      background-color: #41362f;
      padding: 2px 10px;
      border-radius: 10rem;
    }

    &__high {
      color: #ff2c2c;
      background-color: #48343c;
      padding: 2px 10px;
      border-radius: 10rem;
    }
  }

  ul.MuiList-root.MuiList-padding {
    margin-top: -1px;
    padding: 0;
    background-color: #fff !important;
    width: 100px;
    border: 1px solid #f3f6f7;

    li {
      font-size: 12px;

      &:hover {
        background-color: #33363e;
      }
    }

    .drop-down-list-priority-grow {
      &__low {
        color: #ffcf2d;

        &:hover {
          background-color: rgba(255, 255, 45, 0.05);
        }
      }

      &__medium {
        color: #ff8700;

        &:hover {
          background-color: rgba(255, 145, 0, 0.05);
        }
      }

      &__high {
        color: #ff2c2c;

        &:hover {
          background-color: rgba(255, 44, 44, 0.05);
        }
      }
    }
  }
}

.drop-down-list-status,
.drop-down-list-export,
.drop-down-list-assigned {
  & &-btn {
    padding: 0.65rem 1rem;
    border: 1px solid #161b2b;
    background-color: #fff;
    color: #161b2b;
    line-height: 12px;
    font-size: 0.77em;
    text-transform: capitalize;
    border-radius: 15px;
    width: 175px;

    i {
      margin-left: auto;
      font-size: 12px;
      color: #161b2b;
      opacity: 0.5;
    }

    &:hover {
      background-color: #fff;
    }

    &[aria-controls='drop-down-list-status-grow'],
    &[aria-controls='drop-down-list-export-grow'],
    &[aria-controls='drop-down-list-assigned-grow'] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      i {
        transform: rotate(180deg);
      }
    }
  }

  ul.MuiList-root.MuiList-padding {
    margin-top: -1px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff !important;
    width: 175px;
    border: 1px solid #161b2b;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 92px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      background-color: #2b2d34;
      border: 6px solid rgba(0, 0, 0, 0);
      width: 16px;
      background-clip: padding-box;
      border-radius: 9999px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8f939b;
      border: 6px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      width: 3px;
    }

    li {
      font-size: 12px;
      color: #161b2b;
      white-space: pre-wrap;

      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
}

.drop-down-list-export {
  & &-btn {
    color: #fff;
    border: 1px solid var(--primary-color);
    background-color: transparent;
    font-size: 0.9em;

    i {
      color: #fff;
    }

    &:hover {
      background-color: var(--primary-color);
    }
  }

  ul.MuiList-root.MuiList-padding {
    max-height: none;
    border: 1px solid var(--primary-color);
    text-transform: capitalize;
    padding-bottom: 0.65rem;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      width: 0px;
    }

    li {
      font-size: 12px;
      color: #161b2b;
      border-bottom: 1px solid #f0f0f0;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: #fff;
        background-color: #e1cd00;
      }
    }
  }
}

.drop-down-list-commands,
.drop-down-list-reboot {
  & &-btn {
    display: flex;
    justify-content: space-between;
    padding: 9px 16px;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    line-height: 12px;
    font-size: 0.77em;
    text-transform: capitalize;
    border-radius: 19px;

    i {
      font-size: 12px;
      color: var(--primary-color) !important;
    }

    &:hover {
      background-color: var(--primary-color);
      color: #fff !important;

      i {
        color: #fff !important;
      }
    }

    &[aria-controls='drop-down-list-reboot-grow'],
    &[aria-controls='drop-down-list-commands-grow'] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      i {
        transform: rotate(180deg);
        padding-left: 0;
      }
    }
  }

  ul.MuiList-root.MuiList-padding {
    overflow: hidden;
    box-shadow: 0px 3px 3px #00000034;
    margin-top: -1px;
    padding-top: 4px;
    padding-bottom: 12px;
    background-color: #fff !important;
    min-width: 125px;
    border: 1px solid #e5e5e5;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;

    li {
      font-size: 12px;
      color: #4a4a4a;
      padding: 5px 16px;

      &:first-child {
        border-bottom: 1px solid #eeeeee;
      }

      &:hover {
        background-color: var(--primary-color);
        color: #fff;
      }
    }
  }

  .commands-line-item {
    padding: 5px 10px;
    margin: 0 2px;
    line-height: 1.2;
    border-bottom: 1px solid #eeeeee;
    white-space: pre-wrap;
  }
}
