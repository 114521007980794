.m-login__log {
  margin: 0;
}

.topbar-dashb {
  .topBarLogo,
  .company-logo {
    max-height: 32px;
  }
}
