.employee-add-prealoder-container {
    height: 168px;
    display: flex;
    align-items: center;
}

.employee-delete-prealoder-container {
    height: 158px;
    display: flex;
    align-items: center;
}

.user-add-prealoder-container {
    height: 245px;
    display: flex;
    align-items: center;
}

.users-filter {
    padding: 12px 0;

    .input-field {
        height: auto !important;
        margin: 0 !important;
    }
}

.full-list {
    overflow-y: auto;
}

.modal-buttons {
    display: flex;
    margin-top: 4px;
}

#addOEMToSupportForm,
#addPVInstallersToSupportForm {
    .m-checkbox.m-checkbox--state-warning>span:after {
        border-color: #fff !important;
    }
}

.PropMgmtDetails {
    .react-tabs {
        background-color: transparent;

        .m-tabs-line.nav.nav-tabs .nav-link {
            color: #FFFFFF80;

            &:hover {
                color: var(--primary-color);
            }
        }
    }
}
