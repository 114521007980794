.notFoundPage {
  .m-error-6 {
    background: var(--ocean-breeze) !important;
  }

  .errorCode {
    font-size: 10rem;
    line-height: 20rem;
    color: rgba(255, 213, 1, 0.9);
  }

  .errorHeader {
    font-size: 5rem;
    line-height: 2rem;
    color: #fff !important;
  }

  a {
    font-size: 2rem;
  }

  .m-error_description.m--font-light {
    color: #fff !important;
  }
}
