.m-login.m-login--2.m-login-2--skin-1 .m-login__container {
  .m-login__head .m-login__title {
    color: #000 !important;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center !important;
  }

  .m-login__account {
    .m-login__account-link {
      color: var(--primary-color);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;

      &:hover:after {
        border-bottom: 1px solid var(--primary-color) !important;
      }
    }
  }
}

.topLanguageSelector {
  width: fit-content;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  border-radius: 4px;
  padding: 0 6px;

  &:hover {
    border: 1px solid var(--lemon-05);
    color: rgba(255, 255, 255, 0.85);
  }

  .react-select__control {
    cursor: pointer;
    border-bottom: none !important;

    .react-select__value-container {
      color: #121212 !important;

      .react-select__placeholder,
      .react-select__single-value {
        color: #121212 !important;
      }
    }

    .react-select__indicator {
      padding: 0;
      color: #121212 !important;
    }



    &:hover,
    &.react-select__control--is-focused {
      box-shadow: none;
      border: none;
    }
  }
}

.Select.is-focused.is-open>.Select-control {
  .Select-arrow {
    border-color: transparent transparent var(--primary-color) !important;
    border-width: 0 5px 5px !important;
    top: -2px !important;
  }
}

.Select.topLanguageSelector.has-value.Select--single>.Select-control .Select-value .Select-value-label {
  color: #000 !important;
}

.m-link.m-link--light.m-login__account-link {
  color: var(--primary-color) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0;
  border: none;
  cursor: pointer;

  &:hover {
    color: #c8b400 !important;

    &:after {
      border-bottom: 1px solid #c8b400;
    }
  }
}

.m-login.m-login--2.m-login-2--skin-1 {
  .m-login__wrapper {
    margin: 0 0 2rem 0;
    padding: 0.5% 2rem 1rem 2rem;

    .m-login__container {
      margin: 0 auto;
      width: 80%;

      .react-tabs__tab-list {
        border: none;
      }

      .m-login__head {
        margin-bottom: 15px;

        .m-login__desc {
          opacity: 1;
          color: #000;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0;
        }
      }

      .m-have-account {
        margin-top: 40px;
        display: block;
        text-align: center;
      }

      .m-login__form {
        margin-top: 15px;
        margin-bottom: 15px;

        .m-login__form-action {
          margin-top: 30px;

          .m-login__btn {
            border: 1px solid var(--primary-color);
            background-color: var(--primary-color);
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.48px;
            padding: 18px 40px !important;
            height: 54px;

            &.m-login__btn.m-login__btn--primary {
              border: 1px solid var(--primary-color);
              background-color: var(--primary-color);
              color: #fff;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0.48px;
              padding: 18px 40px !important;
              height: 54px;

              &:hover,
              &:focus {
                border-color: var(--lemon-d10);
                color: #fff;
                background-color: var(--lemon-d10);
              }
            }

            &:hover {
              border-color: var(--lemon-d10);
              color: #fff;
              background-color: var(--lemon-d10);
              box-shadow: none !important;
            }

            &:focus {
              border-color: var(--lemon-d10);
              color: #fff;
              background-color: var(--lemon-d10);
            }
          }
        }

        .m-login__form-sub {
          &.terms-cond {
            margin-bottom: -20px !important;
            height: 30px !important;
          }

          &.mae {
            padding-left: 0;
            padding-right: 0;
            margin-right: -15px;
            margin-left: -15px;
          }

          .m-link {
            opacity: 1;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;

            &:hover {
              color: var(--primary-color) !important;
            }
          }

          .m-checkbox {
            opacity: 1;
            color: #161B2B7F;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;

            span {
              border-color: var(--dark-05);
            }

            input:checked~span {
              border-color: var(--primary-color) !important;
            }
          }
        }

        .m-form__group {
          .form-control {
            background: transparent;
            border: none;
            border-radius: 0;
            margin-top: 0;
            padding: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            font-size: 16px !important;
            color: #fff;
            font-weight: 400;
            letter-spacing: 0.48px;
            margin-bottom: 0;

            &:focus {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.m-form__group {
  &>label {
    opacity: 1;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
  }

  .form-control {
    background: transparent;
    border: none;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 16px !important;
    color: #fff;
    font-weight: 400;
    letter-spacing: 0.48px;
    margin-bottom: 0;
  }
}

.m-login__logo {
  margin-top: 24px;

  .Select-arrow-zone {
    padding-right: 0 !important;

    .Select-arrow {
      border-color: var(--primary-color) transparent transparent !important;
    }
  }
}

.btn-focus.m-btn--air {
  box-shadow: 0 10px 20px 0 rgba(255, 184, 34, 0.21) !important;

  &:focus {
    box-shadow: none !important;
  }
}

.m-login__signin,
.m-login__forget-password {
  margin: 0 auto;
}

.m-login__account .qrscan {
  cursor: pointer;
}