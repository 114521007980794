.invited-users-wrapper {
  .rt-noData.customNoData {
    top: 95px;
    left: calc(50% + 15px);
  }
  .react-tabs__tab-list {
    border: none;
    background-color: #3f99cc;
  }

  .m-tabs-line.nav.nav-tabs .nav-link {
    color: #FFFFFF80;
  }
}

.invited-users {
  font-size: 18px !important;
}